<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row>
          <el-col :span="5">
            <div>
              <el-form>
                <el-form-item label="学校">
                  <el-select v-model="queryInfo.name" placeholder="请选择院校">
                    <span v-for="(item, index) in schoolList" :key="index">
                      <el-option
                        :label="item.schoolName"
                        :value="item.schoolName"
                      ></el-option>
                    </span>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="1">
            <div>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchHandle"
              ></el-button>
            </div>
          </el-col>
          <el-col :span="1" :push="17">
            <el-form>
              <el-form-item>
                <div class="add-btn" >
                  <el-button
                    type="primary"
                    @click="addData.isOpen = !addData.isOpen"
                    >添加</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="majorList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="schoolName" label="院校"> </el-table-column>
          <el-table-column prop="majorName" label="专业"> </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"> </el-table-column>
          <el-table-column prop="disable" label="状态">
            <template v-slot="scoped">
              <el-switch
                v-model="scoped.row.disable"
                :active-value="0"
                :inactive-value="1"
                active-text="启用"
                inactive-text="禁用"
                @change="isDisableMajor(scoped.row.disable, scoped.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200"
            v-permission="['school:major:save','school:major:delete']"
          >
            <template v-slot="scoped">
              <el-button
                type="warning"
                v-permission="['school:major:save']"
                size="small"
                @click="updateMajorHandle(scoped.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                v-permission="['school:major:delete']"
                size="small"
                @click="deleteMajorHandle(scoped.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <add-major-dialog
      :addData="addData"
      @closeAddDialog="closeAddDialog"
    ></add-major-dialog>
    <update-major-dialog
      :updateData="updateData"
      @closeUpdateDialog="closeUpdateDialog"
    ></update-major-dialog>
  </div>
</template>

<script>
import {
  getMajorList,
  deleteMajor,
  updateMarjorDisable,
  getSchoolList
} from '@/api/api.js'
import addMajorDialog from './dialog/addMajor.vue'
import updateMajorDialog from './dialog/updateMajor.vue'
import store from '@/store/index'

export default {
  components: {
    addMajorDialog,
    updateMajorDialog
  },
  data () {
    return {
      schoolList: [],
      permissionList: [],
      majorList: [],
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        name: ''
      },
      total: 0,
      isAddDialog: false,
      updateData: {
        isOpen: false,
        id: ''
      },
      addData: {
        isOpen: false
      }
    }
  },
  created () {
    this.getMajorData()
    this.getSchoolList()
    this.permissionList = store.getters['login/getPermission']
  },
  watch: {
    addMajorDialog (data) {
      this.getMajorData()
    }
  },
  methods: {
    /* 获取专业列表 */
    getMajorData () {
      getMajorList(this.queryInfo).then(res => {
        res.data.list.sort((a, b) => a.sort - b.sort)
        this.majorList = res.data.list
        this.total = res.data.total
      })
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getMajorData()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getMajorData()
    },
    /* 打开更新窗口 */
    updateMajorHandle (id) {
      this.updateData = {
        isOpen: true,
        id: id
      }
    },
    /* 关闭添加弹窗 */
    closeAddDialog (data) {
      this.addData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getMajorData()
      }
    },
    /* 关闭更新弹窗 */
    closeUpdateDialog (data) {
      this.updateData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getMajorData()
      }
    },
    /* 删除专业 */
    deleteMajorHandle (id) {
      this.$confirm('此操作将永久删除该专业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMajor(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getMajorData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /* 启用、禁用专业 */
    isDisableMajor (disable, id) {
      const data = {
        disable,
        id
      }
      updateMarjorDisable(data)
    },
    /* 获取学校列表 */
    getSchoolList () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 根据学校搜索专业 */
    searchHandle () {
      this.getMajorData()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>

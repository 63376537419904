<template>
  <div class="page-coantainer">
    <el-dialog
      title="编辑专业"
      :visible.sync="updateData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="院校" prop="schoolId">
            <el-select v-model="formData.schoolId" placeholder="请选择活动区域">
                <span v-for="(item, index) in schoolList" :key="index">
                    <el-option :label="item.schoolName" :value="item.id"></el-option>
                </span>
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="majorName">
            <el-input v-model.trim="formData.majorName"></el-input>
          </el-form-item>
          <el-form-item label="培养目标">
            <el-input type="textarea" v-model.trim="formData.trainingGoal"></el-input>
          </el-form-item>
          <el-form-item label="主要课程">
            <el-input type="textarea" v-model.trim="formData.mainCourse"></el-input>
          </el-form-item>
          <el-form-item label="就业方向">
            <el-input type="textarea" v-model.trim="formData.workDirection"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNum">
            <el-input type="number" v-model.trim="formData.sort"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpdateMajor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSchoolList, updateMajor, getMajorInfoById } from '@/api/api.js'
export default {
  props: {
    updateData: {
      type: Object
    }
  },
  data () {
    return {
      isOpen: false,
      formData: {
        majorName: '',
        schoolId: '',
        trainingGoal: '',
        mainCourse: '',
        workDirection: '',
        sort: ''
      },
      schoolList: [],
      rules: {
        majorName: [
          { required: true, message: '请填写专业', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        schoolId: [
          { required: true, message: '请选择院校', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'updateData.isOpen' (data) {
      if (data) {
        this.getSchoolData()
        this.getMajorInfoById(this.updateData.id)
      }
    }
  },
  methods: {
    /* 获取学校列表 */
    getSchoolData () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 更新专业 */
    submitUpdateMajor () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          updateMajor({
            id: this.updateData.id,
            majorName: this.formData.majorName,
            schoolId: this.formData.schoolId,
            trainingGoal: this.formData.trainingGoal,
            mainCourse: this.formData.mainCourse,
            workDirection: this.formData.workDirection,
            sort: this.formData.sort
          }).then(res => {
            this.isOpen = false
            this.$emit('closeUpdateDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '更新成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 根据id获取专业信息 */
    getMajorInfoById (id) {
      getMajorInfoById(id).then(res => {
        this.formData = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.isOpen = false
      this.$emit('closeUpdateDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>

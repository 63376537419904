<template>
  <div class="page-coantainer">
    <el-dialog
      title="添加专业"
      :visible.sync="addData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="院校" prop="schoolId">
            <el-select v-model="formData.schoolId" placeholder="请选择院校">
                <span v-for="(item, index) in schoolList" :key="index">
                    <el-option :label="item.schoolName" :value="item.id"></el-option>
                </span>
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="majorName">
            <el-input v-model.trim="formData.majorName"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitAddMajor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSchoolList, addMajor } from '@/api/api.js'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        majorName: '',
        schoolId: ''
      },
      schoolList: [],
      rules: {
        majorName: [
          { required: true, message: '请填写专业', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        schoolId: [
          { required: true, message: '请选择院校', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'addData.isOpen' (data) {
      if (data) {
        this.getSchoolData()
      }
    }
  },
  methods: {
    /* 获取学校列表 */
    getSchoolData () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 添加专业 */
    submitAddMajor () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addMajor(this.formData).then(res => {
            this.$emit('closeAddDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeAddDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
